import React from "react"

const useDetectInactivity = (
  fn = () => {}, 
  time = 15000, 
  performAction = true
) => {
  const timer = React.useRef(null);
  const [actionPending, setActionPending] = React.useState(performAction)

  const restartTimer = () => {
    if (timer.current) {
      clearTimeout(timer.current)
    }
    timer.current = setTimeout(() => {
      fn()
      setActionPending(false)
    }, time)
  }

  const handleClick = () => {
    restartTimer()
  }

  React.useEffect(() => {
    if (!actionPending) {
      return
    }
    restartTimer()
    window.addEventListener('click', handleClick)

    return () => {
      if (timer.current) {
        clearTimeout(timer.current)
        window.removeEventListener('click', handleClick);
      }
    }
  }, [actionPending])
}

export default useDetectInactivity
