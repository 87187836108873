import { isBrowser } from "./browserUtils"

const trackGoogleAdConversion = (formType = "lead-form") => {
  function callback() { 
    console.log("conversion captured successfully")
  }

  let eventCode = 'AW-10987330475/F4-iCPzGvN4DEKu3lfco'
  if (formType === "prospecting-form") {
    eventCode = 'AW-10987330475/N4NmCIyfg-QDEKu3lfco'
  }

  if (isBrowser) {
    window.gtag('event', 'conversion', {
        'send_to': eventCode,
        'event_callback': callback
    })
  }
}

export default trackGoogleAdConversion