import React from "react"
import styled from "styled-components";
import { StaticImage } from "gatsby-plugin-image";

import { Button } from "../core/Button";
import { Section, SectionWrapper, SubTitle } from "../core/commonExports"
import { useLeadFormData } from "../../context/LeadFormContext";
import CallScheduleModal from "./CallScheduleModal";
import { OnMobile } from "../DesktopMobileComps";
import { scrollToTop } from "../../utils/scrollToTop";
import ClaimGstModal from "../LeadFormSteps/ClaimGstModal";
import { mobileNumWithCountryCode, trackMixpanelEvent } from "../LeadFormSteps/sharedUtils";
import useDetectInactivity from "../../hooks/useIsInactive";
import { getPageName } from "../../utils/getCurrentPageName";
import { useMixpanel } from "../../context/MixpanelContext";
import checkOfficeHours from "../../utils/checkOfficeHours";

import PhoneIcon from "../../assets/images/phone-icon-alt.svg"

const CallScheduleSection = ({rfqId}) => {
  const mixpanel = useMixpanel()
  const pageName = getPageName()
  const [leadformData, dispatch] = useLeadFormData()
  const mobileNum = leadformData?.mobile || ""
  const [showCallScheduleModal, setShowCallScheduleModal] = React.useState(false)
  const [gstPopupShown, setGSTpopupShown] = React.useState(false)
  const [openGstPopup, setOpenGstPopup] = React.useState(false)

  const handleCallAdvisor = () => {
    window.open("tel:+919076277062")
  }
  const handleShowCallScheduleModal = () => {
    scrollToTop(600) 
    setShowCallScheduleModal(true)
  }
  const handleCloseCallScheduleModal = () => setShowCallScheduleModal(false)

  const closeGstPopup = () => {
    setOpenGstPopup(false)
  }

  const getProspectingData = (data, status) => {
    if (!status) return 
    return ({
      Phone: mobileNumWithCountryCode(mobileNum),
      Data: data,
      Status: status
    })
  }

  const showGSTPopup = (popupOnClick = true) => {
    if (popupOnClick) {
      let mxEventName = `Clicked 'Claim savings with GST'`
      trackMixpanelEvent(mixpanel, mxEventName, pageName)
    }
    setGSTpopupShown(true)
    scrollToTop()
    setOpenGstPopup(true)
  }
  const showGSTPopupAfterDelay = Boolean(rfqId && !gstPopupShown)

  useDetectInactivity(
    () => showGSTPopup(false), 
    10000, 
    showGSTPopupAfterDelay
  )

  const isOfficeHours = checkOfficeHours()

  const callScheduleBtn = (
    <ScheduleCallBtn 
      label="Schedule a call" 
      uppercaseLabel={false} 
      btnIcon={<PhoneIcon className="icon" />}   
      onClick={handleShowCallScheduleModal}
    />
  )

  const callAdvisorBtn = (
    <CallAdvisorBtn 
      label="Your advisor" 
      uppercaseLabel={false} 
      btnIcon={<PhoneIcon className="icon" />} 
      onClick={handleCallAdvisor}
    />
  )

  return (
    <Section background="#E8ECFE">
      <SectionWrapper>
        <Container style={{width: '100%', position: 'relative', alignItems: 'center'}}>
          <HeadingContainer style={{marginLeft: '0', maxWidth: '650px'}}>
            <SubTitle fontSize="36px" mfontSize="24px" style={{ marginBottom: '40px' }} mobileStyles={{textAlign: "left"}}>
              Need to know more before making a&nbsp;
              <span style={{fontFamily: "inherit", fontSize: "inherit", color: "rgba(79, 118, 232, 1)"}}>
                purchase
              </span>? Talk to our Insurance Advisor.
            </SubTitle>
            <CTAContainer>
              {/* {callScheduleBtn} */}
              {callAdvisorBtn}
            </CTAContainer>
          </HeadingContainer>
          <div style={{width: "min(440px, 100%)", height: "auto"}}>
            <StaticImage
              src="../../assets/images/self-serve-schedule-call.webp"
              alt="schedule-call"
              style={{marginLeft: '1.5rem'}}
              loading='lazy'
              // width={440}
              // height={273}
            />
          </div>
          <OnMobile style={{width: "auto", marginTop: "1rem"}}>
            {/* {callScheduleBtn} */}
            {callAdvisorBtn}
          </OnMobile>
        </Container>
        {showCallScheduleModal && mobileNum && (
          <CallScheduleModal 
            mobileNum={mobileNum} 
            handleModalClose={handleCloseCallScheduleModal} 
            handleClaimGST={showGSTPopup} 
          />
        )}
        {openGstPopup ? (
          <ClaimGstModal 
            autoSaveGST={false}
            getProspectingData={getProspectingData}
            handleApiError={() => {}}
            closeGstPopup={closeGstPopup}
          />
        ) : null}
      </SectionWrapper>
    </Section>
  )
}

const CallAdvisorBtn = styled(Button)`
  height: 58px;
  padding: 0.5rem 0.75rem; 
  border: 2px solid #B3B3B3;
  border-radius: 8px;
  font-weight: 600;
  font-size: 18px;
  line-height: 30px;
  color: #333333;
  background: transparent;
  box-shadow: none;
  @media screen and (max-width: 768px) {
    font-weight: 700;
    font-size: 14px;
    line-height: 18px;
    height: 42px;
  }
  .icon {
    margin-right: 0.25rem;
  }
`

const ScheduleCallBtn = styled(CallAdvisorBtn)`
  border: none;
  color: #fff;
  background: #02475E;
  .icon > path {
    fill: #fff;
  }
  @media screen and (max-width: 768px) {
    margin: auto;
    margin-top: 1.5rem;
  }
`

const CTAContainer = styled.div`
  display: flex;
  gap: 22px;
  @media screen and (max-width: 768px) {
    /* flex-direction: column; */
    display: none;
    width: 100%;
    gap: 12px;
  }
`

const HeadingContainer = styled.div`
  margin-left: 150px;
  width: 100%;

  @media screen and (max-width: 768px) {
    margin-left: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
`;

const Container = styled.div`
  margin: 50px 0px;
  display: flex;
  width: 80%;
  align-items: center;
  justify-content: space-between;

  @media screen and (max-width: 768px) {
    flex-direction: ${({ reverse }) =>
      `${reverse ? "column-reverse" : "column"}`};
    width: 100%;
    justify-content: flex-end;
    align-items: flex-end;
  }
`;

export default CallScheduleSection